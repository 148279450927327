$(document).ready(function() {

    init_marquee();


    $('.BottomBanner__minimizeButton').on('click', function() {
        var minimized = $(this).attr('data-minimized');

        if (minimized == 'false') {
            minimize_banner();
        } else {
            maximize_banner();
        }
    });

    var is_minimized = readCookie('banner_minimized');

    if (is_minimized == '1') {
        minimize_banner();
    } else {
        maximize_banner();
    }



});

function init_marquee() {
    var marquee = $('div.marquee');
    marquee.each(function() {
        var mar = $(this);
        var indent = mar.width();
        clearInterval(mar.data('interval'));

        mar.marquee = function() {
            indent--;
            mar.css('text-indent',indent);
            if (indent < -1 * mar.children('div.marquee-text').width()) {
                indent = mar.width();
            }
        };
        mar.data('interval',setInterval(mar.marquee,1000/60));
    });
}

function minimize_banner() {

    var banner = $('.BottomBanner');

    banner.find('.BottomBanner__minimizeButton').attr('data-minimized', 'true')
        .find('i').removeClass('hvr-icon-hang').addClass('hvr-icon-bob').css('margin-top', '-13px');

    banner.addClass('BottomBanner--minimized');
    banner.find('.BottomBanner__imageContainer').slideUp();
    init_marquee();

    createCookie('banner_minimized', '1', 1);

}

function maximize_banner() {
    var banner = $('.BottomBanner');
    banner.find('.BottomBanner__minimizeButton').attr('data-minimized', 'false')
        .find('i').removeClass('hvr-icon-bob').addClass('hvr-icon-hang').css('margin-top', '0px');

    banner.removeClass('BottomBanner--minimized');
    banner.find('.BottomBanner__imageContainer').slideDown();
    init_marquee();
    eraseCookie('banner_minimized');
}

function createCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name,"",-1);
}