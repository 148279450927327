

$(document).ready(function() {

    if ($('#map-div').length) {


        google.maps.event.addDomListener(window, 'load', map_init);

    }

    function map_init() {

        var browser_size = $( window ).width();

        if (browser_size > 760) {
            var zoom_set = 14;
            var drag = true;
            var scroll_wheel = true;

        }
        else {
            var zoom_set = 15;
            var drag = false;
            var scroll_wheel = false;
        }

        var acLatlng = new google.maps.LatLng(49.748570,13.409763);

        var mapOptions = {

            zoom: zoom_set,
            panControl: false,
            zoomControl: true,
            mapTypeControl: false,
            scrollwheel: scroll_wheel,
            streetViewControl: true,
            center: acLatlng,
            draggable: drag,

        };

        var mapElement = document.getElementById('map-div');
        var map = new google.maps.Map(mapElement, mapOptions);

        var contentString = '<h4>Umělecká agentura MAGNETIC</h4>Sousedská 1<br>312 00 Plzeň';

        var infowindow = new google.maps.InfoWindow({
            content: contentString
        });


        var marker = new google.maps.Marker({

            position: acLatlng,
            map: map,
            icon: '/img/elements/map_marker.png'

        });

        marker.addListener('click', function() {
            infowindow.open(map, marker);
            map.setZoom(16);
            map.setCenter(marker.getPosition());
        });

    }

    $('.home-slider').slick({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        adaptiveHeight: false,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 3000,
        appendDots: '.home-slider-dots',

        arrows: false
    });

    if ($('.artist-container').length) {

    var containerEl = document.querySelector('.artist-container');
        var mixer = mixitup(containerEl, {
            animation: {
                animateResizeContainer: false // required to prevent column algorithm bug
            },
            load: {
                filter: '.top',
                sort: 'name:asc'
            }
        });
    }

    $('.gallery-item-link').magnificPopup({
        type: 'image',
        gallery:{
            enabled:true
        },
        // Delay in milliseconds before popup is removed
        removalDelay: 300,

        // Class that is added to popup wrapper and background
        // make it unique to apply your CSS animations just to this exact popup
        mainClass: 'mfp-fade'
    });


});




